import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  SwiperContainer,
  Wrapper,
  Header,
  HeaderTitle,
  Like,
  Produktbeschreibung,
  Catchphrase,
  ConfigContainer,
  DetailsContainer,
  DetailsH3,
  Right,
  DetailsWrapper,
  CallToActionContainer,
  DetailsH4,
  DetailsUl,
  DetailsLi,
  Price,
  MoodContainer,
  InnerModalContainer,
  ConfigUL,
  ConfigLi,
  AusrichtungsContainer,
  Ausrichtung,
  AusrichtungsFlexBox,
  ActionContainer,
  OtherFurnitureContainer,
  OtherFurniture,
  VisitUsContainer,
  VisitUsHead,
  VisitUsBody,
  PdfLink,
} from "../styles/templates/generalStyles"
import Heart from "../components/heart"
import FilledHeart from "../components/filledHeart"
import { FavContext } from '../context/FavContext'
import Modal from "../components/Modal"
import FormModal from "../components/form-modal"
import SingleProductForm from "../components/utilities/singleProductForm"
import SuperSwiper from "../components/Swiper"
import WhatsAppLogo from "../assets/WhatsApp_Logo"
import MailIcon from "../assets/MailIcon"


import NewLoftPdf from '../downloads/2019-08-10-Sofa New Loft.pdf'
import NorthPdf from '../downloads/2019-08-22-Sofa North.pdf'
import RavenPdf from '../downloads/2019-08-22-Sofa Raven.pdf'
import DownTownPdf from '../downloads/2021-02-23-Sofa DownTown.pdf'
import FangoPdf from '../downloads/2021-02-08-fango produkt pdf.pdf'
import MonaLisaPdf from '../downloads/2019-08-22-Sofa Mona Lisa.pdf'
import BenPdf from '../downloads/sofasystem ben.pdf'
import ZoeyPdf from '../downloads/2019-08-22-Sofa Zoey.pdf'
import LeoniePdf from '../downloads/leonie.pdf'
import AlexPdf from '../downloads/2019-09-10-Sofa Alex.pdf'
import DavidPdf from '../downloads/2019-08-22-Sofa David.pdf'
import KyliePdf from '../downloads/2019-08-22-Sofa Kylie.pdf'
import RobinPdf from '../downloads/2019-08-22-Sofa Robin.pdf'
import SaraPdf from '../downloads/2019-08-22-Sofa sara.pdf'

const ReadMore = ({ children, maxCharacterCount = 170 }) => {
  const text = children;

  const [isTruncated, setIsTruncated] = useState(true)

  const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;

  const toggleIsTruncated = () => {
    setIsTruncated(false)
  }
  return (
    <Produktbeschreibung>
      {resultString}
      <button
        style={{
          background: "inherit",
          border: "none",
          fontWeight: "600",
          marginLeft: ".3rem",
          fontSize: ".8rem",
          cursor: "pointer"
        }} onClick={toggleIsTruncated}>
        {isTruncated ? "Mehr lesen" : null}
      </button>
    </Produktbeschreibung>
  )
}

const Template = ({ data }) => {
  const item = data.allWpSofa.nodes[0]

  const title = item.title


  const { favs, addFav, removeFav, setAdded } = useContext(FavContext)

  const [isFav, setIsFav] = useState(false)

  const [isEcksofa, setIsEcksofa] = useState(false)

  const itemSlides = item.postTypeSofaFields.slides;
  const itemSlidesLF = itemSlides.map(slide => slide.localFile);
  const itemSlidesCIS = itemSlidesLF.map(slide => slide.childImageSharp);
  const itemSlidesFluid = itemSlidesCIS.map(slide => slide.fluid);
  const altText = itemSlides.map(slide => slide.altText);
  const description = itemSlides.map(slide => slide.description);

  const moebel = item.modelle.nodes.map(node => node.name)

  const variant = item.ausfuehrungen.nodes.map(node => node.name)

  const soloVariantName = variant[0]
  const variantName = variant[1]
  const variantVersion = variant[2]
  const direction = variant[0]

  const farbvarianten = item.postTypeSofaFields.farbauswahl

  const legs = item.postTypeSofaFields.legs

  const itemId = item.id
  const itemSlug = `/sofas/${item.slug}`
  const itemName = moebel[0]
  const itemPrice = item.postTypeSofaFields.price
  const itemVariant = variant[1]
  const itemImg = itemSlidesFluid[0]

  const dimensions = item.postTypeSofaFields.dimensions.map(i => i.value)
  const width = dimensions[0];


  useEffect(() => {
    if (favs.some(fav => fav.id === itemId)) {
      setIsFav(true)
    }
  }, [isFav, favs, itemId])

  useEffect(() => {
    if (direction === "Ausrichtung links") {
      setIsEcksofa(true)
    } else if (direction === "Ausrichtung rechts") {
      setIsEcksofa(true)
    }
    else if (variantName === "Sofa mit Récamière") {
      setIsEcksofa(true)
    }
    else {
      setIsEcksofa(false)
    }
  }, [direction, variantName])

  const handleAdd = (e) => {
    e.preventDefault();
    addFav(
      itemImg,
      itemName,
      itemVariant,
      itemPrice,
      itemSlug,
      itemId)
      .then(() => {
        setIsFav(true)
        setAdded(true);
      })
  }

  const handleRemove = (e) => {
    e.preventDefault();
    removeFav(itemId)
      .then(() => {
        setIsFav(false)
      })
  }



  return (
    <Layout>
      <SEO title={title}></SEO>
      <Container>
        <Wrapper>
          <SwiperContainer>
            <SuperSwiper
              slides={itemSlidesFluid}
              alt={altText}
              slideData={itemSlides}
              description={description}
            />
          </SwiperContainer>
          <Right>
            <div>
              <Header>
                <div>
                  <HeaderTitle>{moebel}</HeaderTitle>
                </div>
              </Header>
              <Catchphrase>"{item.postTypeSofaFields.claim}"</Catchphrase>


              {/* <Produktbeschreibung>
                {item.postTypeSofaFields.description}
              </Produktbeschreibung> */}



              {item.postTypeSofaFields.description && 

              <ReadMore>
                {item.postTypeSofaFields.description}
              </ReadMore>

}


            </div>

            <div>
              {isEcksofa === true &&


                <AusrichtungsContainer>
                  <h3 style={{ fontWeight: "550" }}>Ausrichtung wählen:</h3>
                  <AusrichtungsFlexBox>

                    <Link
                      style={{
                        padding: "0.5rem 2.5rem 0.5rem 2.5rem",
                        border: "1px solid lightgrey"
                      }}
                      activeStyle={{
                        color: "#2398AB",
                        border: "1px solid #2398AB"
                      }} to={`/sofas/${item.postTypeSofaFields.direction.links.slug}`}>Links</Link>

                    <Link
                      style={{
                        display: "inline-block",
                        marginLeft: "1rem",
                        padding: "0.5rem 2.5rem 0.5rem 2.5rem",
                        border: "1px solid lightgrey"
                      }}
                      activeStyle={{
                        color: "#2398AB",
                        border: "1px solid #2398AB"
                      }}
                      to={`/sofas/${item.postTypeSofaFields.direction.rechts.slug}`}>Rechts</Link>

                  </AusrichtungsFlexBox>
                </AusrichtungsContainer>

              }

              <ConfigContainer>
                <h3 style={{ fontWeight: "550" }}>Ausführung wählen:</h3>
                <Modal name={`${isEcksofa === false ? soloVariantName : variantName + " " + variantVersion}`}>
                  <InnerModalContainer>

                    <ConfigUL>
                      {item.postTypeSofaFields.variants &&
                        <>
                          {item.postTypeSofaFields.variants.map(m =>
                            <ConfigLi>
                              <Link activeStyle={{ color: "#2398AB", fontSize: "1.2rem" }} to={`/sofas/${m.link.slug}`}>
                                <Img fluid={m.img.localFile.childImageSharp.fluid} />
                                <h5 style={{ marginTop: "10px", textAlign: "center" }}>{m.variant}</h5>
                              </Link>
                            </ConfigLi>
                          )}
                        </>
                      }
                    </ConfigUL>

                    {/* Farbauswahl */}
                    <div style={{
                      marginTop: "5rem"
                    }}>
                      <h3
                        style={{ color: "#000", marginBottom: "2rem" }}>Bezug- und Farbauswahl (Vorschau):
              </h3>
                      <ConfigUL>
                        {item.postTypeSofaFields.variants &&
                          <>
                            {farbvarianten.map(farbe => (
                              <li
                                key={farbe.description}
                              >
                                <Img
                                  fluid={farbe.localFile.childImageSharp.fluid}
                                />
                                <div>{farbe.description}</div>
                              </li>
                            ))}</>
                        }

                      </ConfigUL>
                    </div>
                  </InnerModalContainer>
                </Modal>
              </ConfigContainer>

              {/* <ConfigContainer>
                <h3>Ausführung wählen:</h3>
                <Modal name={`Ausführung: ${isEcksofa === false ? soloVariantName : variantName + " " + variantVersion} / ${width} cm breit >`}>
                  <InnerModalContainer>
                    <ConfigUL>
                      {variants &&
                        <>
                          {variants.map(m =>
                            <ConfigLi>
                              <Link activeStyle={{ color: "blue", fontSize: "1.2rem" }} to={`/sofas/${m.link.slug}`}>
                                <Img fluid={m.img.localFile.childImageSharp.fluid} />
                                <h5 style={{ marginTop: "10px", textAlign: "center" }}>{m.variant}</h5>
                              </Link>
                            </ConfigLi>
                          )}
                        </>
                      }
                      {newOptions && 
                      <>
                      {newOptions.map(m =>
                      
                      <NewConfigLi>
                      <Link activeStyle={{ color: "green", fontSize: "1.2rem" }} to={`/sofas/${m.link.slug}`}>
                        <Img fluid={m.img.localFile.childImageSharp.fluid} />
                        <h5 style={{ marginTop: "10px", textAlign: "center" }}>{m.variant}</h5>
                      </Link>
                    </NewConfigLi>
                
                      )}
                      </>
                      }
                    </ConfigUL>
                  </InnerModalContainer>
                </Modal>
              </ConfigContainer> */}


              <Price>ab {item.postTypeSofaFields.price} €</Price>

              <CallToActionContainer>



                <ActionContainer>

                  {isFav === false &&
                    <form onSubmit={handleAdd} >
                      <Like type="submit">
                        <Heart />
                      </Like>
                    </form>
                  }


                  {isFav === true &&
                    <form onSubmit={handleRemove} >
                      <Like type="submit">
                        <FilledHeart />
                      </Like>
                    </form>
                  }

                  <FormModal name={`Unverbindliche Anfrage`}>
                    <SingleProductForm
                      moebel={title}
                      farbvarianten={farbvarianten}
                      legs={legs}
                    />
                  </FormModal>
                </ActionContainer>
              </CallToActionContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <span>Haben Sie noch Fragen?</span>
                <div
                  style={{
                    marginTop: ".5rem",
                    display: "flex",
                    alignItems: "center"
                  }}>
                  <button
                    style={{
                      width: "48px",
                      height: "48px",
                      border: "none",
                      background: "inherit"
                    }}>
                    <a href="https://api.whatsapp.com/send?phone=4915236611286" title="Senden Sie uns eine Nachricht über WhatsApp!" target="_blank" rel="noreferrer noopener">
                      <WhatsAppLogo />
                    </a>
                  </button>


                  <a
                    href={`mailto:info@diewohnplaner.de?subject=${title}`}

                    style={{
                      width: "48px",
                      height: "48px",
                      border: "none",
                      background: "inherit",
                      cursor: "pointer"
                    }}>
                    <MailIcon />
                  </a>
                </div>
              </div>
            </div>
          </Right>
        </Wrapper>

        <DetailsH3>Produktdetails</DetailsH3>

        <DetailsContainer>
          <DetailsWrapper>
            <DetailsH4>Abmessungen</DetailsH4>
            <DetailsUl>
              {item.postTypeSofaFields.dimensions.map(i =>
                <DetailsLi>
                  {i.dimension}: {` ${i.value} cm`}
                </DetailsLi>
              )}
            </DetailsUl>
          </DetailsWrapper>

          <DetailsWrapper>
            <DetailsH4>Materialien</DetailsH4>
            <DetailsUl>
              {item.postTypeSofaFields.materials.map(i =>
                <DetailsLi>
                  {i.materialbeschreibung}
                </DetailsLi>
              )}
            </DetailsUl>
          </DetailsWrapper>

          <DetailsWrapper>
            <DetailsH4>Downloads</DetailsH4>
            <DetailsUl>
              <DetailsLi>
                <div style={{ marginTop: "1rem" }}>
                  {moebel[0] === "Kylie" &&
                    <PdfLink href={KyliePdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "David" &&
                    <PdfLink href={DavidPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Ben" &&
                    <PdfLink href={BenPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Robin" &&
                    <PdfLink href={RobinPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Sara" &&
                    <PdfLink href={SaraPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "New Loft" &&
                    <PdfLink href={NewLoftPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "North" &&
                    <PdfLink href={NorthPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Mona Lisa" &&
                    <PdfLink href={MonaLisaPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Raven" &&
                    <PdfLink href={RavenPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Fango" &&
                    <PdfLink href={FangoPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "DownTown" &&
                    <PdfLink href={DownTownPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Zoey" &&
                    <PdfLink href={ZoeyPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Leonie" &&
                    <PdfLink href={LeoniePdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Alex" &&
                    <PdfLink href={AlexPdf} download>PDF</PdfLink>
                  }
                </div>
              </DetailsLi>
            </DetailsUl>
          </DetailsWrapper>

        </DetailsContainer>

        {item.postTypeSofaFields.moodimg &&
          <MoodContainer>
            <Img fluid={item.postTypeSofaFields.moodimg.localFile.childImageSharp.fluid} />
          </MoodContainer>
        }

        <div>
          <Catchphrase>Ähnliche Sofas</Catchphrase>
          <OtherFurnitureContainer>

          {item.postTypeSofaFields.relational &&
<>
            {item.postTypeSofaFields.relational.map(item =>
              <OtherFurniture>
                <Link to={`/sofas/${item.slug}`}>
                  <Img fluid={item.postTypeSofaFields.slides.map(item => item.localFile.childImageSharp.fluid)[0]} alt="" />
                  {item.title}
                </Link>
              </OtherFurniture>
            )}
            </>
            }
          </OtherFurnitureContainer>
        </div>

        <VisitUsContainer>
          <VisitUsHead>Sie haben das Richtige noch nicht gefunden?</VisitUsHead>
          <VisitUsBody>Dann besuchen Sie uns in unserem Geschäft. Wir helfen Ihnen gern dabei, genau das Richtige für Sie zu finden.</VisitUsBody>
        </VisitUsContainer>
      </Container>

      <div type="hidden" style={{ display: 'none' }}>
        <form
          name="Sofa-Formular"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="moebel" />
          <input type="hidden" name="farbe" />
          <input type="hidden" name="fuesse" />
          <input type="hidden" name="nachname" />
          <input type="hidden" name="vorname" />
          <input type="hidden" name="email" />
          <input type="hideen" name="telefonnummer" />
          <input type="hidden" name="wohnort" />
          <button type="submit">Abschicken</button>
        </form>
      </div>

    </Layout>
  )
}

export default Template

export const query = graphql`
query($slug: String!) {
  allWpSofa(filter: {slug: {eq: $slug}}) {
    nodes {
      title
      id
      slug
      seo {
        title
      }
      ausfuehrungen {
        nodes {
          name
        }
      }
      modelle {
        nodes {
          name
        }
      }
      postTypeSofaFields {
        claim
        price
        description
        direction {
          links {
            ... on WpSofa {
              id
              link
              slug
            }
          }
          rechts {
            ... on WpSofa {
              id
              slug
            }
          }
        }
        dimensions {
          dimension
          value
        }
        materials {
          materialbeschreibung
        }
        slides {
          altText
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 750, quality: 75, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        moodimg {
          localFile {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        variants {
          img {
            localFile {
              childImageSharp {
                fluid(quality: 75, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          link {
            ... on WpSofa {
              id
              slug
            }
          }
          variant
        }
        farbauswahl {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 250, maxHeight: 250, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        legs {
           variant
        }
        relational {
          ... on WpSofa {
            id
            title
            slug
            postTypeSofaFields {
              slides {
                  localFile {
                    childImageSharp {
                      fluid(quality: 75) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
              }
            }
          }
        }
      }
    }
  }
}
`