import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  Field,
  Form,
  Formik,
  ErrorMessage,
  FormikHelpers
} from "formik"
import * as Yup from "yup"
import {
  ConfigUL,
  ConfigLi,
  BackArrow,
} from "../../styles/templates/generalStyles"
import {
  CustomInput,
  ColorButton,
  Bottombar,
  BottomFlex,
  FertigButton,
  SuccessContainer,
  SubmitButton,
} from "../../styles/components/formstyles"

const CustomImg = styled(Img)``

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Bitte geben Sie eine gültige E-Mail-Adresse ein.")
    .required("*Wir benötigen eine eine gültige E-Mail-Adresse."),
})

const SingleProductForm = props => {
  const firstColor = props.farbvarianten.map(farbe => farbe.description)
  const actualFirstColor = firstColor[0]

  const [open, setOpen] = useState(false)
  
  const [color, setColor] = useState(actualFirstColor)
  const [colorImg, setColorImg] = useState(
    props.farbvarianten.map(farbe => farbe.localFile.childImageSharp.fluid)
  )
  const options = props.legs

  return (
    <Formik
      enableReinitialize
      initialValues={{
        moebel: props.moebel,
        farbe: color,
        fuesse: [],
        vorname: "",
        nachname: "",
        email: "",
        telefonnummer: "",
        wohnort: "",
      }}
      onSubmit={(data, 
        { setStatus, resetForm }
        ) => {
        console.log(data)
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "Sofa-Formular",
            ...data,
          }),
        })
          .then(res => {
            setStatus(res.status)
            if (res.status === 200) {
              resetForm()
              setStatus({
                sent: true,
                msg: "Vielen Dank! Wir werden uns in Kürze bei Ihnen melden!",
              })
            }
          })
          .catch(err => {
            resetForm()
            setStatus({
              sent: false,
              msg: `Error! ${err}. Versuchen Sie es bitte später nochmal!`,
            })
          })
       }}
      validationSchema={SignupSchema}
    >
      {({ status, handleBlur ,isSubmitting, handleChange}) => {
      
        return (
        <Form
          name="Sofa-Formular"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <Field type="hidden" name="form-name" />
          <Field type="hidden" name="bot-field" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <Field
            style={{
              
              fontSize: ".6rem"
            }}
             as={CustomInput} name="moebel" disabled />

            <label htmlFor="farbe">Bezug & Farbe wählen:</label>
            <Field type="hidden" name="farbe" />

            <ColorButton type="button" onClick={() => setOpen(true)}>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                }}
              >
                <CustomImg fluid={colorImg} />
              </div>
              <div style={{ marginLeft: "1rem" }}>{color}</div>
            </ColorButton>

            {open && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: ".5rem",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#fff",
                  
                      paddingTop: ".5rem",
                      paddingBottom: ".5rem",
                      zIndex: 9999
                    }}
                  >
                    <BackArrow onClick={() => setOpen(false)}></BackArrow>
                  </div>
                  <ConfigUL>
                    {props.farbvarianten.map(farbe => (
                      <ConfigLi
                        key={farbe.description}
                        onClick={() => {
                          setColor(farbe.description)
                          setColorImg(farbe.localFile.childImageSharp.fluid)
                        }}
                      >
                        <CustomImg
                          fluid={farbe.localFile.childImageSharp.fluid}
                        />
                        <div>{farbe.description}</div>
                      </ConfigLi>
                    ))}
                  </ConfigUL>

                  <Bottombar>
                    <BottomFlex>
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        <CustomImg fluid={colorImg} />
                      </div>
                      {color}

                      <FertigButton onClick={() => setOpen(false)}>
                        Fertig 
                      </FertigButton>
                    </BottomFlex>
                  </Bottombar>
                </div>
              </>
            )}

            <label htmlFor="fuesse">Füße auswählen</label>
            <Field 
            style={{
              marginBottom: ".8rem"
            }}
            id="fuesse" component="select" label="select" name="fuesse" onChange={handleChange}>
              {options.map(o => (
                <option key={o.variant} value={o.variant}>{o.variant}</option>
              ))}
            </Field>

            <label htmlFor="nachname">Nachname</label>
            <Field as={CustomInput} name="nachname" required />

            <label htmlFor="vorname">Vorname</label>
            <Field as={CustomInput} name="vorname" required />

            <label htmlFor="email">E-Mail</label>
            <Field as={CustomInput} type="email" name="email" required />
            <ErrorMessage name="email" component="div" />

            <label htmlFor="telefonnummer">Telefonnummer</label>
            <Field
              as={CustomInput}
              type="telefonnummer"
              name="telefonnummer"
              required
            />
            <ErrorMessage name="telefonnummer" component="div" />

            <label htmlFor="wohnort">Wohnort</label>
            <Field as={CustomInput} name="wohnort" required />

            {status && status.msg && (
              <SuccessContainer>{status.msg}</SuccessContainer>
            )}
            <SubmitButton type="submit"  disabled={isSubmitting}> {isSubmitting ? <span>Sendet...</span> : <span>Anfrage abschicken</span>}</SubmitButton>
          </div>
          
        </Form>
        
      )}
      }
    </Formik>
  )
}

export default SingleProductForm
